import { Component, Prop, Ref, Watch } from 'vue-property-decorator'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import { ValidationObserver } from 'vee-validate'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

@Component({
  name: 'GtrEditLanguageForm'
})
export default class GtrEditLanguageForm extends GtrSuper {
    @Prop({ required: true, type: Boolean, default: false })
    visible: boolean | undefined

    @Prop({ required: true, type: String })
    event_uuid: string | undefined

    @Prop({ required: true, type: Object })
    language: any | undefined

    @Ref()
    readonly observerEditLanguageForm!: InstanceType<typeof ValidationObserver>;

    data () {
      return {
        submitting: false,
        showForm: false,
        editLanguage: {
          language: null,
          display: null,
          uuid: null
        }
      }
    }

    @Watch('visible', { immediate: true })
    onVisibleValueChange (newVisibleValue: boolean) {
      this.$data.showForm = newVisibleValue
    }

    @Watch('language', { immediate: true })
    onLanguageChange (language: any) {
      this.$data.editLanguage.display = language.display
      this.$data.editLanguage.language = language.name
      this.$data.editLanguage.uuid = language.uuid
    }

    get languagePrefixItems () {
      const items: any[] = []
      for (const prefix in this.defaultLanguages()) {
        const language = this.defaultLanguages()[prefix]
        items.push({
          text: language.name,
          value: prefix
        })
      }
      return items
    }

    handleChangeLanguagePrefix (payload: any) {
      this.$data.editLanguage.display = this.defaultLanguages()[payload].name
    }

    async submit () {
      const form = (this.$refs.editLanguageForm as HTMLFormElement)
      if (form.validate()) {
        try {
          this.$data.submitting = true
          const payload: any = {
            event_uuid: this.event_uuid,
            language_uuid: this.$data.editLanguage.uuid,
            data: {
              name: this.$data.editLanguage.language,
              display: this.$data.editLanguage.display
            }
          }
          await this.$store.dispatch('languages/updateLanguage', payload)
          Container.get(Notification).success('Language successfully updated.')
          this.handleCloseForm()
        } catch (error) {
          Container.get(ErrorHandlerService).error(error)
        } finally {
          this.$data.submitting = false
        }
      }
    }

    handleCloseForm () {
      this.$data.editLanguage = {
        language: null,
        display: null,
        uuid: null
      }
      this.observerEditLanguageForm.reset()
      this.$emit('close')
    }
}

import { Component, Vue, Watch } from 'vue-property-decorator'
import GtrEventLayout from '@/modules/common/views/layouts/level-two/event/event.layout.vue'
import GtrNewLanguageForm from './forms/new/new.form.vue'
import GtrEditLanguageForm from './forms/edit/edit.form.vue'
import { mapState } from 'vuex'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'

@Component({
  name: 'GtrEventLangugesView',
  components: {
    'gtr-new-language-form': GtrNewLanguageForm,
    'gtr-edit-language-form': GtrEditLanguageForm
  },
  computed: {
    ...mapState('languages', ['languages'])
  }
})
export default class GtrEventLanguagesView extends Vue {
  data () {
    return {
      loading: false,
      table: {
        headers: [
          { text: 'Name', align: 'start', sortable: true, value: 'display' },
          { text: 'Prefix', align: 'center', sortable: true, value: 'name' },
          { text: '', sortable: false, searchable: false, value: 'actions', width: '90px' }
        ],
        items: []
      },
      forms: {
        showNewLanguageForm: false,
        showEditLanguageForm: false
      },
      languageToDelete: null,
      languageToEdit: null
    }
  }

  created () {
    this.$emit('update:layout', GtrEventLayout)
  }

  async mounted () {
    await this.fetchLanguages()
  }

  @Watch('languages', { immediate: true })
  onLanguagesChange (languages: any[]) {
    this.$data.table.items = languages
  }

  async handleAction () {
    await this.fetchLanguages()
  }

  handleShowNewLanguageForm () {
    this.$data.forms.showNewLanguageForm = true
  }

  handleCloseNewLanguageForm () {
    this.$data.forms.showNewLanguageForm = false
  }

  handleShowEditLanguageForm (payload: any) {
    this.$data.languageToEdit = payload
    this.$data.forms.showEditLanguageForm = true
  }

  handleCloseEditLanguageForm () {
    this.$data.languageToEdit = null
    this.$data.forms.showEditLanguageForm = false
  }

  handleDeleteLanguage (language: any) {
    this.$data.languageToDelete = language
  }

  async onDeleteLanguageAction (payload: any) {
    if (payload.confirm) {
      this.$data.loading = true
      await this.$store.dispatch('languages/deleteLanguage', {
        event_uuid: this.$route.params.event_uuid,
        language_uuid: this.$data.languageToDelete.uuid
      })
      Container.get(Notification).success('Language successfully deleted.')
      this.$data.loading = false
      this.$data.languageToDelete = null
    } else {
      this.$data.languageToDelete = null
    }
  }

  private async fetchLanguages () {
    this.$data.loading = true
    await this.$store.dispatch('languages/fetchLanguages', this.$route.params.event_uuid)
    this.$data.loading = false
  }
}

import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { ValidationObserver } from 'vee-validate'
import { Component, Prop, Ref, Watch } from 'vue-property-decorator'

@Component({
  name: 'GtrNewLanguageForm'
})
export default class GtrNewLanguageForm extends GtrSuper {
    @Prop({ required: true, type: Boolean, default: false })
    visible: boolean | undefined

    @Prop({ required: true, type: String })
    event_uuid: string | undefined

    @Ref()
    readonly observerNewLanguageForm!: InstanceType<typeof ValidationObserver>;

    data () {
      return {
        submitting: false,
        showForm: false,
        newLanguage: {
          language: null,
          display: null
        }
      }
    }

    @Watch('visible', { immediate: true })
    onVisibleValueChange (newVisibleValue: boolean) {
      this.$data.showForm = newVisibleValue
    }

    get languagePrefixItems () {
      const items: any[] = []
      for (const prefix in this.defaultLanguages()) {
        if (!this.existLanguage(prefix)) {
          const language = this.defaultLanguages()[prefix]
          items.push({
            text: language.name,
            value: prefix
          })
        }
      }
      return items
    }

    handleChangeLanguagePrefix (payload: any) {
      this.$data.newLanguage.display = this.defaultLanguages()[payload].name
    }

    async submit () {
      const form = (this.$refs.newLanguageForm as HTMLFormElement)
      if (form.validate()) {
        try {
          this.$data.submitting = true
          const payload: any = {
            event_uuid: this.event_uuid,
            data: {
              name: this.$data.newLanguage.language,
              display: this.$data.newLanguage.display
            }
          }
          await this.$store.dispatch('languages/createLanguage', payload)
          Container.get(Notification).success('Language successfully created.')
          this.handleCloseForm()
        } catch (error) {
          Container.get(ErrorHandlerService).error(error)
        } finally {
          this.$data.submitting = false
        }
      }
    }

    handleCloseForm () {
      this.$data.newLanguage = {
        language: null,
        display: null
      }
      this.observerNewLanguageForm.reset()
      this.$emit('close')
    }

    private existLanguage (name: string) {
      const result = this.$store.state.languages.languages.find((language: any) => language.name === name)
      return result !== undefined
    }
}
